import React, { useEffect, useState } from "react";
import GeneralUtility from "../../../../../libs/GeneralUtility";
import { Select, TYPE } from "baseui/select";
import { SIZE, Tag } from "baseui/tag";
import useWindowSize from "../../../../../hooks/useWindowSize";

const SearchInput = ({
  facets,
  radius,
  placeholder,
  searchInputOverrides = {},
  maxDropdownHeight,
  onSearchFormInputChange,
  onSearchChange,
}) => {
  const [searchFormValues, setSearchFormValues] = useState([]);

  const { isMobile } = useWindowSize();

  // create static options for global select field
  useEffect(() => {
    const newSearchFormValues = [];
    const staticOptions = Object.keys(facets).reduce((acc, facetTypeKey) => {
      const facetType = facets[facetTypeKey];

      return {
        ...acc,
        [GeneralUtility.decodeHtmlCharCodes(facetType.title)]:
          facetType.items.map((facetItem) => {
            const item = {
              ...facetItem,
              customValue: `${facetTypeKey}_${facetItem.uid}`,
              facetTypeKey: facetTypeKey,
              facetTypeTitle: facetType.title,
            };

            if (facetItem.selected) {
              newSearchFormValues.push(item);
            }
            return item;
          }),
      };
    }, {});
    setOptions(staticOptions);
    setSearchFormValues(newSearchFormValues);
  }, [facets]);

  const [options, setOptions] = useState({});

  const createFacetItem = (
    facetType,
    title,
    value,
    additionalSearchField,
    selected = false
  ) => ({
    uid: value,
    title,
    additionalSearchField,
    facetType: facetType,
    customValue: `${facetType}_${value}`,
    selected: selected ? 1 : 0,
  });

  /**
   * handle add / delete of select item
   *
   * @param value
   */
  const handleSearchChange = ({ value }) => {
    setSearchFormValues(value);
    onSearchChange && onSearchChange(value);
  };

  /**
   * handle every text change of search input
   *
   * @param event
   */
  const handleSearchFormInputChange = (event) => {
    onSearchFormInputChange &&
      onSearchFormInputChange(event, setOptions, createFacetItem);
  };

  return (
    <Select
      options={options}
      labelKey="title"
      valueKey="customValue"
      placeholder={placeholder}
      maxDropdownHeight={!isMobile ? maxDropdownHeight : undefined}
      openOnClick={false}
      type={TYPE.search}
      multi
      onChange={handleSearchChange}
      onInputChange={handleSearchFormInputChange}
      filterOptions={(
        options,
        filterValue,
        excludeOptions,
        { labelKey, valueKey }
      ) => {
        return options
          ? options.filter(
              (option) =>
                option[labelKey]
                  .toLowerCase()
                  .includes(filterValue.toLowerCase()) ||
                option?.additionalSearchField
                  ?.toLowerCase()
                  .includes(filterValue.toLowerCase()) ||
                option?.search_input_mapping
                  ?.toLowerCase()
                  .includes(filterValue.toLowerCase())
            )
          : options;
      }}
      value={searchFormValues}
      overrides={{
        ...searchInputOverrides,
        Root: {
          ...searchInputOverrides?.Root,
          style: {
            ...searchInputOverrides?.Root?.style,
            width: "100%",
          },
        },
        DropdownContainer: {
          ...searchInputOverrides?.DropdownContainer,
          style: {
            ...searchInputOverrides?.DropdownContainer?.style,
            zIndex: "1000",
          },
        },
        Popover: {
          props: {
            overrides: {
              Body: {
                style: {
                  zIndex: "1000",
                },
              },
            },
          },
        },
        ControlContainer: {
          ...searchInputOverrides?.ControlContainer,
          style: {
            ...searchInputOverrides?.ControlContainer?.style,
            backgroundColor: "#fff",
            minHeight: "60px",
          },
        },
        ValueContainer: {
          style: {
            flexWrap: "nowrap",
          },
        },
        Tag: {
          component: (props) => {
            return (
              <Tag
                {...props}
                key={`${props.value.title}.${props.value.uid}`}
                size={SIZE.medium}
                overrides={{
                  Text: {
                    style: {
                      maxWidth: "inherit",
                    },
                  },
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    fontSize: ".9rem",
                    lineHeight: ".8rem",
                  }}
                >
                  <span>
                    {props.value.title}{" "}
                    {props.value.facetType === "Location" &&
                      "Ø " + radius + "km"}
                  </span>
                  <span
                    style={{
                      fontSize: ".65rem",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: props.value.facetTypeTitle,
                    }}
                  />
                </div>
              </Tag>
            );
          },
        },
      }}
    />
  );
};

export default SearchInput;
